<template>
	<v-card>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
			<v-spacer></v-spacer>
			<v-btn :color="primaryColor" dark style="margin-right: 10px" @click="getImagesList()">
				<i :title="$t('common.update')" class="fas fa-sync" />
			</v-btn>

			<!-- DIALOG FOR WIZARD -->
			<!-- :title="titleWizard" fullscreen hide-overlay transition="dialog-bottom-transition" -->
			<v-dialog v-if="hideWizard" v-model="wizard" persistent max-width="50%">
				<template v-slot:activator="{ on, attrs }">
					<v-btn :color="primaryColor" dark v-bind="attrs" v-on="on"> {{ $t('agentList.wizard') }} </v-btn>
				</template>
				<v-card>
					<v-toolbar dark :color="primaryColor">
						<v-toolbar-title>{{ $t('agentList.wizardToolbar') }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn icon dark :color="primaryColor" @click="handleClose_Wizard()">
							<i class="fa fa-window-close" />
						</v-btn>
					</v-toolbar>
					<v-stepper v-model="activeStepWizard" alt-labels>
						<v-stepper-header style="margin-top: 20px">
							<v-stepper-step step="1">{{ $t('agentList.selectDatasource') }} </v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step step="2"> {{ $t('agentList.typeAgent') }} </v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step step="3"> {{ $t('agentList.Selectdatamodel') }} </v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step step="4"> {{ $t('agentList.configuration') }} </v-stepper-step>
						</v-stepper-header>
						<v-stepper-content step="1">
							<v-col cols="12">
								<v-row style="margin-left: -10px; margin-right: -10px">
									<v-col cols="4" style="margin-bottom: 5px">
										<div @click="(selectDataSource = 'agent-api'), (idTypeAgent = 2)">
											<v-card
												:class="{
													'selected-card': selectDataSource == 'agent-api'
												}"
											>
												<v-card-title>AGENT API</v-card-title>
												<v-card-text>
													{{ $t('wizard.description_api_agentType') }}
													<v-chip :color="'#8BC34A'" text-color="white" style="margin-left: 5px; margin-right: 10px">
														{{ $t('wizard.icon_P_S') }}
													</v-chip>
													<v-chip :color="'#F44336'" text-color="white"> {{ $t('wizard.icon_O_D') }} </v-chip>
												</v-card-text>
											</v-card>
										</div>
									</v-col>

									<v-col cols="4" style="margin-bottom: 5px">
										<div @click="(selectDataSource = 'agent-local-txt'), (idTypeAgent = 1)">
											<v-card
												:class="{
													'selected-card': selectDataSource == 'agent-local-txt'
												}"
											>
												<v-card-title>LOCAL TXT FILE</v-card-title>
												<v-card-text>
													{{ $t('wizard.description_local_txt_file_agentType') }}
													<v-chip :color="'#F44336'" text-color="white" style="margin-left: 5px">
														{{ $t('wizard.icon_O_D') }}
													</v-chip>
												</v-card-text>
											</v-card>
										</div>
									</v-col>

									<v-col cols="4" style="margin-bottom: 5px">
										<div @click="(selectDataSource = 'agent-local-excel'), (idTypeAgent = 2)">
											<v-card
												:class="{
													'selected-card': selectDataSource == 'agent-local-excel'
												}"
											>
												<v-card-title>LOCAL EXCEL FILE</v-card-title>
												<v-card-text>
													{{ $t('wizard.description_local_excel_file_agentType') }}
													<v-chip :color="'#8BC34A'" text-color="white" style="margin-left: 5px; margin-right: 10px">
														{{ $t('wizard.icon_P_S') }}
													</v-chip>
													<v-chip :color="'#F44336'" text-color="white"> {{ $t('wizard.icon_O_D') }} </v-chip>
												</v-card-text>
											</v-card>
										</div>
									</v-col>

									<v-col cols="4" style="margin-bottom: 5px">
										<div @click="(selectDataSource = 'agent-mqtt-listener'), (idTypeAgent = 0)">
											<v-card
												:class="{
													'selected-card': selectDataSource == 'agent-mqtt-listener'
												}"
											>
												<v-card-title>MQTT LISTENER</v-card-title>
												<v-card-text>
													{{ $t('wizard.description_mqtt_listener_agentType') }}
													<v-chip :color="'#8BC34A'" text-color="white" style="margin-left: 5px">
														{{ $t('wizard.icon_P_S') }}
													</v-chip>
												</v-card-text>
											</v-card>
										</div>
									</v-col>

									<v-col cols="4" style="margin-bottom: 5px">
										<div @click="(selectDataSource = 'agent-api-listener'), (idTypeAgent = 0)">
											<v-card
												:class="{
													'selected-card': selectDataSource == 'agent-api-listener'
												}"
											>
												<v-card-title>API LISTENER</v-card-title>
												<v-card-text>
													{{ $t('wizard.description_api_listener_agentType') }}
													<v-chip :color="'#8BC34A'" text-color="white" style="margin-left: 5px">
														{{ $t('wizard.icon_P_S') }}
													</v-chip>
												</v-card-text>
											</v-card>
										</div>
									</v-col>

									<v-col cols="4" style="margin-bottom: 5px">
										<div @click="(selectDataSource = 'agent-rabbitMQ-listener'), (idTypeAgent = 0)">
											<v-card
												:class="{
													'selected-card': selectDataSource == 'agent-rabbitMQ-listener'
												}"
											>
												<v-card-title>RABBITMQ LISTENER</v-card-title>
												<v-card-text>
													{{ $t('wizard.description_rabbitMQ_listener_agentType') }}
													<v-chip :color="'#8BC34A'" text-color="white" style="margin-left: 5px">
														{{ $t('wizard.icon_P_S') }}
													</v-chip>
												</v-card-text>
											</v-card>
										</div>
									</v-col>
								</v-row>
								<v-row style="margin-bottom: 5px">
									<v-chip :color="'#8BC34A'" text-color="white" style="margin-right: 10px">
										{{ $t('wizard.icon_P_S_description') }}
									</v-chip>
									<v-chip :color="'#F44336'" text-color="white"> {{ $t('wizard.icon_O_D_description') }} </v-chip>
								</v-row>

								<v-row>
									<v-btn :disabled="step < 2" @click="activeStepWizard--" :color="primaryColor" style="margin-right: 10px">{{
										$t('wizard.previous')
									}}</v-btn>
									<v-btn
										v-if="activeStepWizard < 4"
										:disabled="
											(activeStepWizard == 1 && !selectDataSource) ||
											(activeStepWizard == 2 && !selectTypeAgent) ||
											(activeStepWizard == 3 && !selectDataModel)
										"
										@click="obtainDataModel_Properties()"
										:color="primaryColor"
										>{{ $t('wizard.next') }}</v-btn
									>
								</v-row>
							</v-col>
						</v-stepper-content>

						<v-stepper-content step="2">
							<v-col cols="12">
								<v-row style="margin-left: -10px; margin-right: -10px">
									<v-col v-if="idTypeAgent == 0 || idTypeAgent == 2">
										<div @click="selectTypeAgent = 'publish-subscribe'">
											<v-card
												:class="{
													'selected-card': selectTypeAgent == 'publish-subscribe'
												}"
											>
												<v-card-title>SUBSCRIPTION</v-card-title>
												<v-card-text> {{ $t('wizard.publish_subscribeDescription') }} </v-card-text>
											</v-card>
										</div>
									</v-col>

									<v-col v-if="idTypeAgent == 1 || idTypeAgent == 2">
										<div @click="selectTypeAgent = 'on-demand'">
											<v-card
												:class="{
													'selected-card': selectTypeAgent == 'on-demand'
												}"
											>
												<v-card-title>ON DEMAND</v-card-title>
												<v-card-text> {{ $t('wizard.on_demandDescription') }} </v-card-text>
											</v-card>
										</div>
									</v-col>
								</v-row>
								<v-row>
									<v-btn
										:disabled="activeStepWizard < 1"
										@click="activeStepWizard--"
										:color="primaryColor"
										style="margin-right: 10px"
										>{{ $t('wizard.previous') }}</v-btn
									>
									<v-btn
										v-if="activeStepWizard < 4"
										:disabled="
											(activeStepWizard == 1 && !selectDataSource) ||
											(activeStepWizard == 2 && !selectTypeAgent) ||
											(activeStepWizard == 3 && !selectDataModel)
										"
										@click="obtainDataModel_Properties()"
										:color="primaryColor"
										>{{ $t('wizard.next') }}</v-btn
									>
								</v-row>
							</v-col>
						</v-stepper-content>

						<v-stepper-content step="3">
							<v-row>
								<v-btn
									:disabled="activeStepWizard < 1"
									@click="activeStepWizard--"
									:color="primaryColor"
									style="margin-right: 10px"
									>{{ $t('wizard.previous') }}</v-btn
								>
							</v-row>
						</v-stepper-content>

						<v-stepper-content step="4">
							<v-row>
								<v-btn
									:disabled="activeStepWizard < 1"
									@click="activeStepWizard--"
									:color="primaryColor"
									style="margin-right: 10px"
									>{{ $t('wizard.previous') }}</v-btn
								>
							</v-row>
						</v-stepper-content>
					</v-stepper>
				</v-card>
			</v-dialog>
		</v-card-title>
		<!-- DATATABLE -->
		<v-data-table :headers="headers" :items="list" :search="search">
			<template v-slot:item.actions="{ item }">
				<!--v-if="item.type === 'string'-->
				<v-btn v-if="item.type !== 'no-modify'" icon :color="primaryColor" @click="startAgent(item)" :title="$t('agentList.createInstance')">
					<i class="fas fa-edit" />
				</v-btn>
				<v-btn
					v-if="item.type !== 'no-modify'"
					icon
					:color="primaryColor"
					:title="$t('agentList.deleteImage')"
					@click="showDeleteImage(item)"
				>
					<i class="fa fa-trash" />
				</v-btn>
			</template>
		</v-data-table>
		<!-- MODAL DIALOG FOR DELETING IMAGES -->
		<v-dialog persistent v-model="dialogDeleteImage" max-width="35%">
			<v-card>
				<v-card-title class="text-h5"
					>{{ $t('agentList.deleteImageDialogQuestion') }}<v-spacer></v-spacer>
					<v-btn icon :color="primaryColor" @click="closeDeleteImage()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="deleteImageConfirm()">{{ $t('common.ok') }}</v-btn>
					<v-btn color="error" @click="closeDeleteImage()">{{ $t('common.cancel') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- MODAL FOR STARTING AN AGENT. FIELDS CREATED DINAMICALLY -->
		<v-dialog persistent v-model="startAgentDialog" max-width="40%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('agentList.startAgentDialogTitle') }}
					<v-spacer></v-spacer>
					<v-btn icon :color="primaryColor" @click="handleCloseAgentDialog()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<div style="padding: 20px">
					<v-form v-model="startAgentForm" ref="initialTemplate" style="margin-left: 50px">
						<div v-if="initialTemplate.env.length > 0">
							<!-- NOMBRE IMAGEN -->
							<v-text-field
								v-if="initialTemplate.Image"
								:label-width="label_widthColumn"
								:label="initialTemplate.Image.name"
								v-model="initialTemplate.Image.value"
								disabled
								style="width: 80%"
							/>
							<!-- NOMBRE CONTENEDOR -->
							<v-text-field
								v-if="initialTemplate.ContainerName"
								:label-width="label_widthColumn"
								:label="initialTemplate.ContainerName.name"
								v-model="initialTemplate.ContainerName.value"
								prop="ContainerName.value"
								required
								:rules="[(v) => !!v || initialTemplate.ContainerName.name + requiredText]"
								:disabled="initialTemplate.ContainerName.disabled"
								:placeholder="$t('agentList.containerName_characteristics')"
								style="width: 80%"
							/>
							<div v-for="(item, indexTemplate) in initialTemplate.env" :key="indexTemplate">
								<div v-if="item.type === 'string' && item.name === 'CALLBACK_URL'"></div>

								<div v-if="item.type === 'string' && item.name != 'CALLBACK_URL'"></div>

								<div v-if="item.type === 'number'">
									<!-- TIME INTERVAL -->
									<v-text-field
										style="width: 80%"
										v-model="initialTemplate.env[indexTemplate].value"
										required
										type="number"
										:label="$t('agentList.time_interval')"
										:rules="[(v) => !!v || initialTemplate.env[indexTemplate].name + requiredText]"
									/>
								</div>

								<div v-if="item.type === 'time_unit'">
									<v-select
										style="width: 80%"
										v-model="initialTemplate.env[indexTemplate].value"
										:items="time_unit_items"
										required
										:label="$t('agentList.time_unit')"
										:rules="[(v) => !!v || initialTemplate.env[indexTemplate].name + requiredText]"
									></v-select>
								</div>

								<div v-if="item.type === 'port_type'">
									<v-text-field
										style="width: 80%"
										v-model="initialTemplate.env[indexTemplate].value"
										required
										type="number"
										:label="$t('agentList.port')"
										:rules="[(v) => !!v || initialTemplate.env[indexTemplate].name + requiredText]"
										@keydown.enter.prevent="runAgent('initialTemplate')"
									/>
									<!-- hide-details
										single-line
										-->
								</div>

								<div v-if="item.type === 'pollutant_type'">
									<v-select
										style="width: 80%"
										v-model="initialTemplate.env[indexTemplate].value"
										:items="pollutants_items"
										required
										:label="$t('agentList.pollutant')"
										:rules="[(v) => !!v || initialTemplate.env[indexTemplate].name + requiredText]"
									>
										<template v-slot:item="{ item }">
											{{ item.label }}
										</template>
										<template v-slot:selection="{ item }">
											{{ item.value }}
										</template>
									</v-select>
								</div>
								<div v-if="item.type === 'water_pollutant_type'">
									<v-select
										style="width: 80%"
										v-model="initialTemplate.env[indexTemplate].value"
										:items="water_pollutants_items"
										required
										:label="$t('agentList.pollutant')"
										:rules="[(v) => !!v || initialTemplate.env[indexTemplate].name + requiredText]"
									></v-select>
								</div>
								<div v-if="item.type === 'forecast_interval'">
									<v-select
										style="width: 80%"
										v-model="initialTemplate.env[indexTemplate].value"
										:items="forecast_intervals"
										required
										:label="$t('agentList.forecastInterval')"
										:rules="[(v) => !!v || initialTemplate.env[indexTemplate].name + requiredText]"
									>
										<template v-slot:item="{ item }">
											{{ item.label }}
										</template>
										<template v-slot:selection="{ item }">
											{{ item.value }}
										</template>
									</v-select>
								</div>
								<div v-if="item.type === 'days_interval'">
									<v-text-field
										style="width: 80%"
										v-model="initialTemplate.env[indexTemplate].value"
										required
										type="number"
										:label="$t('agentList.days_interval')"
										:rules="[(v) => !!v || initialTemplate.env[indexTemplate].name + requiredText]"
										@keydown.enter.prevent="runAgent('initialTemplate')"
									/>
								</div>
							</div>
						</div>
						<div v-else class="noParameters">
							{{ $t('agentList.noParameters') }}
							<!-- NOMBRE IMAGEN -->
							<v-text-field
								v-if="initialTemplate.Image"
								:label-width="label_widthColumn"
								:label="initialTemplate.Image.name"
								v-model="initialTemplate.Image.value"
								disabled
								style="width: 80%"
							/>
							<!-- NOMBRE CONTENEDOR -->
							<v-text-field
								v-if="initialTemplate.ContainerName"
								:label-width="label_widthColumn"
								:label="initialTemplate.ContainerName.name"
								v-model="initialTemplate.ContainerName.value"
								prop="ContainerName.value"
								required
								:rules="[(v) => !!v || initialTemplate.ContainerName.name + requiredText]"
								:disabled="initialTemplate.ContainerName.disabled"
								:placeholder="$t('agentList.containerName_characteristics')"
								style="width: 80%"
							/>
						</div>
					</v-form>
				</div>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="runAgent('initialTemplate')">{{ $t('common.accept') }}</v-btn>
					<v-btn color="error" @click="closeAgentDialog()">{{ $t('common.cancel') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { getImages, deleteImageById, recoverTemplate } from '@/api/images_API';
import { createAgent } from '@/api/agents_API';
import { OnDemandAgent } from '@/api/onDemand';
import { dockerObjectForFilter } from '@/api/common';
import { createInfoRegister } from '@/api/info_API';
import constants from '@/utils/constants';
export default {
	data() {
		return {
			search: '',
			headers: [
				{
					text: 'Name',
					align: 'start',
					value: 'name'
				},
				{
					text: 'Tag',
					value: 'tag'
				},
				{
					text: 'Type',
					value: 'type'
				},
				{
					text: 'Actions',
					value: 'actions'
				}
			],
			list: [],
			wizard: false,
			selectDataSource: null,
			selectTypeAgent: null,
			selectDataModel: null,
			idTypeAgent: null,
			step: 1,
			activeStepWizard: 1,
			dialogDeleteImage: false,
			imageToDelete: '',
			imageID: null,
			isOnDemand: false,
			callback_url_set: '',
			initialTemplate: {
				env: []
			},
			startAgentDialog: false,
			startAgentForm: false,
			label_widthColumn: '60%',
			requiredText: '',
			rules: [],
			hideWizard: false,
			water_pollutants_items: constants.WATER_POLLUTANTS,
			pollutants_items: constants.POLLUTANTS,
			time_unit_items: constants.WINDOW_TIME_UNIT,
			forecast_intervals: constants.WINDOW_SIZE_FORECAST,
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {},
	watch: {},
	mounted() {},
	created() {
		this.getImagesList();
		this.commonTranslation();
		// List of all keys in the localStorage
		/*const items = { ...localStorage };
		console.log(items);

		const items2 = { ...sessionStorage };
		console.log(items2);

		var session = this.$store.getters.getSession;
		console.log(session.usr);*/
	},
	methods: {
		runAgent(formName) {
			//Valido los campos obligatorios
			if (this.$refs[formName].validate()) {
				this.initialTemplate.ContainerName.value = this.initialTemplate.ContainerName.value.replace(/\s+/g, '');

				//console.log('Properties');
				//console.log(this.initialTemplate.ContainerName.value);
				//console.log(this.initialTemplate);
				const agent = this.constructAgentObject(this.initialTemplate);
				console.log('AGENT', agent);

				if (this.isOnDemand) {
					// On-demand
					var envVariable = agent.Env[0];
					var itemEnvVariable = envVariable.split('=');
					var on_demandObject = {
						name: agent.ContainerName,
						image: agent.Image,
						environment: [
							{
								key: itemEnvVariable[0],
								value: itemEnvVariable[0]
							}
						]
					};
					OnDemandAgent(on_demandObject).then(
						(response) => {
							console.log(response);
							this.startAgentDialog = false;
							this.$notify({
								title: this.$t('common.success'),
								message: this.$t('agentList.agentCreated'),
								type: 'success',
								duration: 2000
							});
						},
						(error) => {
							this.startAgentDialog = false;
							this.$notify({
								title: this.$t('common.error'),
								message: error,
								type: 'error',
								duration: 2000
							});
						}
					);
				} else {
					// Publish Subscribe
					console.log('Let´s go to create the agent');
					console.log(agent);
					createAgent(agent).then(
						(response) => {
							console.log('Create Agent');
							console.log(response.message);
							this.startAgentDialog = false;
							//Notificación a la UI. Agente creado (message, title)
							var titleDialog = this.$t('common.agent');
							titleDialog += ' ' + agent.ContainerName + ' ';
							titleDialog += this.$t('agentList.agentCreated');
							this.$puiNotify.success(titleDialog, this.$t('common.success'));
							// Crear inserción en Info Register
							var containerData = {};
							if (this.initialTemplate.env.length == 0) {
								containerData = {
									random_id: this.random_ID,
									container_name: agent.ContainerName,
									organizationName: agent.agentProject /*,
									time_interval: 0,
									time_unit: 'No Units'*/
								};
							} else {
								containerData = {
									random_id: this.random_ID,
									container_name: agent.ContainerName,
									organizationName: agent.agentProject /*,
									time_interval: this.initialTemplate.env[0].value,
									time_unit: this.initialTemplate.env[1].value*/
								};
								// Complete dinamically the object with the environmental variables
								this.initialTemplate.env.forEach((e) => {
									containerData[e.name] = e.value;
								});
							}
							console.log('-------- PREVIO A INSERTAR EN INFOS ------');
							console.log(containerData);
							createInfoRegister(containerData).then((response) => {
								console.log('RESPUESTA DE INFO REGISTER', response);
								this.random_ID = '';
							});
						},
						(error) => {
							this.startAgentDialog = false;
							// Notificación de error a la UI
							console.log(error);
							this.$puiNotify.error(error, this.$t('common.error'));
						}
					);
				}
			}
		},

		constructAgentObject(data) {
			var agent = {
				ContainerName: data.ContainerName.value,
				Hostname: '',
				Domainname: '',
				User: '',
				Image: data.Image.value,
				Env: [],
				agentType: data.type,
				agentProject: data.project
			};

			data.env.forEach((e) => {
				const key = e.name;
				const value = e.value;
				agent.Env.push(`${key}=${value}`);
			});

			return agent;
		},

		handleCloseAgentDialog() {
			this.startAgentDialog = false;
		},

		closeAgentDialog() {
			this.imageID = null;
			this.startAgentDialog = false;
		},

		getImagesList() {
			var dockerFilter = dockerObjectForFilter(this.session);
			getImages(dockerFilter).then((response) => {
				this.list = response.message;
				console.log('lista de imágenes');
				console.log(this.list);
			});
		},

		// Metodo para algunas traducciones comunes al crear el formulario
		commonTranslation() {
			this.requiredText = ' ' + this.$t('common.required');
		},

		obtainDataModel_Properties() {
			if (this.activeStepWizard < 3) {
				this.activeStepWizard++;
			}
		},

		// Manejador para cerrar el Wizard
		handleClose_Wizard() {
			this.wizard = false;
			this.resetWizard();
		},

		// Reseteo a los valores por defecto del Wizard
		resetWizard() {
			this.activeStepWizard = 1;
			this.selectDataSource = '';
			this.selectTypeAgent = '';
			this.selectDataModel = '';
		},

		// Initializes the agent
		startAgent(item) {
			this.imageID = item.Id;
			if (item.type == 'on_demand') {
				this.isOnDemand = true;
			} else {
				this.isOnDemand = false;
			}
			console.log('On demand agent: ', this.isOnDemand);
			recoverTemplate(item).then((response) => {
				console.log('TEMPLATES');
				console.log(response.message);
				var enviro = response.message.environment;
				enviro.forEach((prop) => {
					if (prop.key === 'RANDOM_ID') {
						this.random_ID = prop.value;
					}
				});
				if (this.isOnDemand) {
					var environment = response.message.environment;
					environment.forEach((prop) => {
						if (prop.key === 'CALLBACK_URL') {
							this.callback_url = prop.value;
							this.callback_url_set = this.$t('agentList.callback_url_set') + prop.value;
						}
					});
				}
				this.buildCreatingAgent(response.message);
				this.startAgentDialog = true;
			});
		},

		// Show modal for deleting the image
		showDeleteImage(item) {
			console.log(item);
			this.dialogDeleteImage = true;
			this.imageToDelete = item.Id;
			this.imageNameToDelete = item.name;
		},

		// Confirm for deleting the image
		deleteImageConfirm() {
			deleteImageById(this.imageToDelete)
				// eslint-disable-next-line
				.then((response) => {
					this.getImagesList();
					// Notificación para la UI
					var titleDialog = this.$t('common.image');
					titleDialog += ' ' + this.imageNameToDelete + ' ';
					titleDialog += this.$t('agentList.deletedImage');
					this.$puiNotify.success(titleDialog, this.$t('common.success'));
					this.imageToDelete = '';
					this.imageNameToDelete = '';
					this.dialogDeleteImage = false;
				})
				// eslint-disable-next-line
				.catch((error) => {
					console.log('---------------');
					console.log(error.message);
					console.log('---------------');
					var errMessage = error.message;
					if (errMessage.includes('409')) {
						//Hay un contenedor usando esta imagen
						this.$puiNotify.error(this.$t('agentList.imageInUse'), this.$t('common.error'));
					} else {
						// Revise que docker este funcionando
						this.$puiNotify.error(this.$t('agentList.reviewDockerInUse'), this.$t('common.error'));
					}

					this.imageToDelete = '';
					this.imageNameToDelete = '';
					this.dialogDeleteImage = false;
				});
		},

		// Close modal that deletes the image
		closeDeleteImage() {
			this.dialogDeleteImage = false;
			this.imageToDelete = '';
		},

		// Method for creating dinamically the form that start the images
		buildCreatingAgent(template) {
			console.log('prueba');
			console.log(template);
			var environmentalVariables = {
				Image: {},
				ContainerName: {},
				env: [],
				project: template.project,
				type: template.type
			};

			var imageName = {
				name: 'IMAGE NAME',
				type: 'string',
				value: template.image,
				required: false,
				disabled: true,
				variable: 'image'
			};
			environmentalVariables.Image = imageName;

			var containerName = {
				name: 'AGENT NAME',
				type: 'string',
				value: '',
				required: true,
				disabled: false,
				variable: 'name'
			};
			environmentalVariables.ContainerName = containerName;

			template.environment.forEach((input) => {
				var itemInput = JSON.parse(JSON.stringify(input));

				if (itemInput.key !== 'RANDOM_ID') {
					// console.log(itemInput)
					var type = '';
					var value = '';
					switch (itemInput.key) {
						case 'TIME_INTERVAL':
							type = 'number';
							break;
						case 'AGENT_TYPE':
							type = 'agent_type';
							break;
						case 'TIME_UNIT':
							type = 'time_unit';
							break;
						case 'POLLUTANT':
							type = 'pollutant_type';
							break;
						case 'WATER_POLLUTANT':
							type = 'water_pollutant_type';
							break;
						case 'PORT':
							type = 'port_type';
							break;
						case 'SUBTYPE':
							value = itemInput.value;
							break;
						case 'NETWORK_NAME':
							value = itemInput.value;
							break;
						case 'FORECAST_INTERVAL':
							type = 'forecast_interval';
							break;
						case 'DAYS_INTERVAL':
							type = 'days_interval';
							break;
						default:
							type = 'string';
							break;
					}
					var itemOption = {
						name: itemInput.key,
						type: type,
						value: value,
						required: true,
						disabled: false
					};
					environmentalVariables.env.push(itemOption);
				}
			});

			this.initialTemplate = environmentalVariables;
			console.log('INITIAL TEMPLATE');
			console.log(this.initialTemplate);
		}
	}
};
</script>

<style lang="postcss" scoped>
.selected-card {
	border: 1px solid #0dea4c;
}

.v-card {
	min-height: 150px;
}

.noParameters {
	font-weight: bold;
	text-align: center;
	white-space: pre;
}
</style>
