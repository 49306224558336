import request from '@/utils/request_API';
import constants from '../utils/constants';

export function createInfoRegister(data) {
	return request({
		url: '/info',
		method: constants.METHOD_POST,
		data: data,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		}
	});
}
