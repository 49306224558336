import request from '@/utils/request_API';
import constants from '@/utils/constants';

export function createAgent(data) {
	return request({
		url: '/agents/ngsiagent',
		method: constants.METHOD_POST,
		data: data,
		headers: {
			'Content-Type': constants.Application_Json,
			Accept: constants.Application_Json
		}
	});
}

export function inspectAgentById(id) {
	return request({
		url: '/agents/ngsiagent/' + id + '/inspect',
		method: constants.METHOD_GET
	});
}

/*export function getAgents() {
	return request({
		url: '/agents',
		method: constants.METHOD_GET
	});
}*/

export function getAgents(data) {
	return request({
		url: '/agents',
		method: constants.METHOD_POST,
		data: data,
		headers: {
			'Content-Type': constants.Application_Json,
			Accept: constants.Application_Json
		}
	});
}

export function getLog(id, since) {
	return request({
		url: `/agents/${id}/log?since=${since}`,
		method: constants.METHOD_GET
	});
}

export function pauseAgent(id) {
	return request({
		url: `/agents/${id}/stop`,
		method: constants.METHOD_PATCH,
		timeout: 15000
	});
}

export function playAgent(id) {
	return request({
		url: `/agents/${id}/start`,
		method: constants.METHOD_PATCH
	});
}

/*export function deleteAgent(id) {
	return request({
		url: `/agents/ngsiagent/${id}`,
		method: constants.METHOD_DELETE
	});
}*/

export function deleteAgentandInfoRegister(data) {
	return request({
		url: '/agents/deleteAgentandInfoRegister',
		method: constants.METHOD_POST,
		data: data,
		headers: {
			'Content-Type': constants.Application_Json,
			Accept: constants.Application_Json
		}
	});
}
