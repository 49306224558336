<template>
	<div :style="appearance">
		<v-tabs v-model="tab">
			<v-tab>{{ $t('agents.images') }} </v-tab>
			<v-tab> {{ $t('agents.containers') }} </v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab">
			<v-tab-item><AgentList /></v-tab-item>
			<v-tab-item><ContainerList /></v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import AgentList from './component/agentList.vue';
import ContainerList from './component/containerList.vue';
export default {
	name: 'agentsmanagement',
	components: {
		AgentList,
		ContainerList
	},
	data() {
		return {
			appearance: {
				height: '100%',
				width: '100%',
				overflow: 'scroll',
				position: 'absolute'
			},
			tab: null
		};
	},
	beforeCreate() {
		this.$vuetify.lang.current = this.$store.getters.getSession.language;
	},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
